import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step help`}</strong>{` -- display help for the specified command or command group`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step help <command>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step help`}</strong>{` command displays help for a command or command group.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--http`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
HTTP service address (e.g., ':8080')`}</p>
    <p><strong parentName="p">{`--html`}</strong>{`=`}<inlineCode parentName="p">{`directory`}</inlineCode>{`
The export `}<inlineCode parentName="p">{`directory`}</inlineCode>{` for HTML docs.`}</p>
    <p><strong parentName="p">{`--markdown`}</strong>{`=`}<inlineCode parentName="p">{`directory`}</inlineCode>{`
The export `}<inlineCode parentName="p">{`directory`}</inlineCode>{` for Markdown docs.`}</p>
    <p><strong parentName="p">{`--report`}</strong>{`
Writes a JSON report to the HTML docs directory.`}</p>
    <p><strong parentName="p">{`--hugo`}</strong>{`
Writes hugo (vs jekyll) compatible markdown files`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Display help for `}<strong parentName="p">{`step ca certificate`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step help ca certificate
`}</code></pre>
    <p>{`Display help for `}<strong parentName="p">{`step ssh`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step help ssh
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      